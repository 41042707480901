<template>
  <div class="container">
    <div class="content">
      <!-- 面包屑 -->
      <div class="bread">
        <h5>当前位置：</h5>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">汇成国际</el-breadcrumb-item>
          <el-breadcrumb-item>积分商城</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="shop">
        <img src="../assets/img/point-bg.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PointShop",
  data() {
    return {};
  },

  created() {
  },

  components: {},

  methods: {},

  computed: {},
};
</script>
<style lang='less' scoped>
.content{
  width: 1200px;
  margin: 20px auto 90px;
}
.bread{
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  h5{
    font-size: 18px;
    font-family: PingFang-SC-Bold;
    line-height: 24px;
    color: #000000;
  }
  span{
    font-weight: 400 !important;
    font-size: 16px;
    font-family: PingFangSC-Regular;
    color: #666666 !important;
  }
}
.bread /deep/ .is-link{
      font-weight: 400 !important;
    font-size: 16px;
    font-family: PingFangSC-Regular;
    color: #666666 !important;
}
.shop {
  display: flex;
  width: 100%;
  img {
    width: 1200px;
  }
}
</style>